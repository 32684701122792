.admin-bar .banner {
	top: 46px;
}

@media screen and (min-width: 783px) {
	.admin-bar .banner {
		top: 32px;
	}
}

.admin-bar .banner .container {
	top: 46px;
}

@media screen and (min-width: 783px) {
	.admin-bar .banner .container {
		top: 32px;
	}
}

.admin-bar .social__banner {
	top: 126px;
}

@media screen and (min-width: 783px) {
	.admin-bar .social__banner {
		top: 112px;
	}
}

.alert {
	position: fixed;
	width: 100%;
	color: $white;
	background: $brand-secondary;
	z-index: 1001;
	height: 40px;
	line-height: 40px;
	text-align: center;

	a {
		color: $white;
		font-weight: 300;
		text-decoration: underline;
	}
}

.banner {
	@include container();
	height: 50px;
	position: relative;
	width: 100%;

	@include breakpoint($medium) {
		height: 80px;
	}

	.container {
		@include container();
		position: fixed;
		z-index: 1000;
		top: 0;
		background-color: $white;
		width: 100%;
		border-bottom: 1px solid $gray-lighter;

		@include breakpoint($medium) {
			border-bottom: 1px solid $gray-lighter;
		}
	}

	.menu-bar {
		@include container();
		position: fixed;
		top: 0;
		z-index: 10;
		width: 100%;
		background-color: $white;
		border-bottom: 1px solid $gray-lighter;

		@include breakpoint($medium) {
			position: relative;
			background-color: transparent;
			width: span(2 of 12);
			float: left;
			border-bottom: 0;
		}
	}

	.brand {
		@include hide-text();
		background-position: 0;
		height: 50px;
		line-height: 50px;
		float: left;
		width: 150px;
		background-size: 70px auto;
		background-repeat: no-repeat;
		margin-left: 2%;

		@include breakpoint($medium) {
			height: 80px;
			line-height: 80px;
			background-size: 100px auto;
			margin-left: 0;
			background-position: 50%;
		}
	}

	.menu-drawer {
		position: relative;
		display: block;
		float: right;
		top: 0;
		height: 50px;
		width: 50px;
		border-radius: 50%;

		/* image replacement */
		overflow: hidden;
		text-indent: 100%;
		white-space: nowrap;

		transition: transform 0.3s;

		.nav-icon {
			/* icon created in CSS */
			@include center;
			position: absolute;
			width: 20px;
			height: 2px;
			border-radius: 1px;
			background-color: $brand-secondary;
			transition: background-color 0.3s;

			&:before,
			&:after {
				/* upper and lower lines of the menu icon */
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				width: 100%;
				height: 100%;
				background-color: inherit;
				border-radius: 1px;

				/* Force Hardware Acceleration in WebKit */
				transform: translateZ(0);
				backface-visibility: hidden;

				transition: transform 0.3s, width 0.3s, top 0.3s;
			}

			&:before {
				transform: translateY(-5px);
			}

			&:after {
				transform: translateY(5px);
			}

			@include breakpoint($medium) {
				visibility: hidden;
				display: none;
			}
		}

		.navigation-is-open & {
			/* rotate trigger when navigation becomes visible */
			transform: rotate(180deg);

			.nav-icon {
				background-color: $white;
				transition: background-color 0.3s;
			}

			.nav-icon::after,
			.nav-icon::before {
				/* animate arrow --> from hamburger to arrow */
				background-color: $brand-secondary;
				transition: transform 0.3s;
			}

			.nav-icon:before {
				transform: rotate(135deg);
			}

			.nav-icon:after {
				transform: rotate(225deg);
			}

			.no-touch &:hover .nav-icon:after,
			.no-touch &:hover .nav-icon:before {
				top: 0;
			}
		}
	}

	.nav-container {
		@include container();
		/* all navigation content */
		height: calc(100% - 50px);
		background-color: $white;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		padding-bottom: $padding-base-vertical;

		/* Force Hardware Acceleration in WebKit */
		transform: translateZ(0);
		backface-visibility: hidden;

		transform: translateY(-100%);
		transition: transform 0.3s;
		transition-timing-function: cubic-bezier(0.6, 0, 0.45, 0.99);

		@include breakpoint($medium) {
			height: 80px;
			transform: translateY(0);
			padding-bottom: 0;
			background-color: transparent;
			overflow: visible;
		}
	}

	.nav-wrapper {
		position: fixed;
		z-index: 2;
		top: 50px;
		left: 0;
		height: 100%;
		width: 100%;
		visibility: hidden;
		transition: visibility 0s 0.3s;

		.navigation-is-open & {
			visibility: visible;
			transition: visibility 0s 0s;

			.nav-container {
				transform: translateY(0);
				transition: transform 0.3s;
				transition-timing-function: cubic-bezier(0.6, 0, 0.45, 0.99);
			}
		}

		@include breakpoint($medium) {
			position: relative;
			visibility: visible;
			top: 0;
			width: auto;
		}
	}

	.nav {
		list-style: none;
		margin: 0;
		padding: 0;
		font-weight: 300;
		text-transform: uppercase;
		line-height: 2;
		text-align: center;

		& .sub-menu {
			list-style: none;
			margin: 0;
			padding: 0;
			font-weight: 300;
			font-size: ms(-1);

			.menu-item {
				margin-bottom: $padding-base-vertical / 2;
				border: 0;
			}
		}

		.menu-item {
			padding-bottom: $padding-base-vertical / 2;
			margin-bottom: $padding-base-vertical / 2;
			border-bottom: 1px solid $off-white;

			a {
				font-weight: 400;
				color: $text-color;

				@include breakpoint($medium) {
					font-weight: 300;
					text-decoration: none;
				}
			}

			.sub-menu a {
				text-decoration: none;
			}
		}
	}

	[data-dropdown="closed"] {
		& > .sub-menu {
			max-height: 0;
			transition: max-height 0.3s;
			overflow: hidden;
		}

		& > a {
			text-decoration: none !important;
		}
	}

	[data-dropdown="opened"] {
		& > .sub-menu {
			max-height: 1000px;
			transition: max-height 0.25s ease-in;
		}

		& > a {
			color: $brand-secondary !important;
			text-decoration: none !important;
		}
	}

	.cta-nav {
		width: span(12 of 12);
		color: $white;
		background: $brand-primary;
		overflow: hidden;

		.nav {
			@include container();
			width: 100%;
			height: 50px;
			line-height: 50px;

			.menu-item {
				position: relative;
				border: 0;
			}

			a {
				color: $white;
				font-weight: 400;
				text-transform: uppercase;
			}

			.sub-menu .menu-item {
				@include span(6 of 12);
				position: absolute;
				background-color: $brand-primary;
			}

			.sub-menu .menu-spenden {
				left: 0;
				bottom: $padding-base-vertical / 2 * -1;
			}

			.sub-menu .menu-mittun {
				right: 0;
				top: 0;
				background: $brand-secondary;
			}
		}
	}

	@include breakpoint($medium) {
		.nav-wrapper {
			@include span(10 of 12);
			position: relative;
			padding-left: 0;
			padding-right: 0;
		}

		.menu-drawer {
			display: none;
		}

		.nav-container {
			width: 100%;
		}

		.nav {
			@include container();
			margin: 0;
			padding: 0;
			list-style: none;
			font-weight: 300;
			text-transform: none;
			width: auto;
			height: 80px;
			line-height: 80px;
			text-align: right;

			li,
			a {
				display: inline-block;
			}

			li {
				text-align: center;
				transition: background-color 0.3s;

				&:hover {
					background-color: $off-white;

					a {
						transition: color 0.3s;
						color: $brand-primary;
						font-weight: 300;
					}
				}
			}

			a {
				text-decoration: none;
				color: $text-color;
				transition: color 0.3s;

				&:hover {
					color: $brand-primary;
				}
			}

			.active {
				> a {
					color: $brand-primary;
					font-weight: 400;
				}
			}
		}

		.nav-primary {
			@include span(9 of 12);
			padding-right: 0;
			float: right;

			.nav {
				> li {
					padding: 0 $padding-base-vertical / 4;
					font-size: ms(-1);
					margin-bottom: 0;
					position: relative;

					> a {
						transition: color 0.3s;

						&:hover {
							color: $brand-primary;
						}
					}

					&:hover {
						&:before {
							top: -1px;
						}

						> ul {
							opacity: 1;
							visibility: visible;
							display: block;
							margin: 0;
							transition: margin 0.3s, opacity 0.3s;
						}
					}

					&.active {
					}
				}

				.sub-menu {
					width: auto;
					min-width: 150px;
					position: absolute;
					top: 100%;
					left: 0;
					z-index: 1;
					opacity: 0;
					display: none;
					visibility: hidden;
					transition: margin 0.3s, opacity 0.3s;
					background-color: $white;
					margin: 0;
					padding: 0;
					line-height: 3;
					box-shadow: 0 2px 4px rgba($gray-base, 0.25);
					font-size: ms(-1);

					> li {
						width: 100%;
						text-align: left;
						padding: 0 $padding-base-vertical;
						background-color: $white;
						transition: background-color 0.3s;
						margin-bottom: 0;

						&.active {
							a {
								color: $text-color;
								font-weight: 500;
							}
						}
					}

					a {
						width: 100%;
						color: $text-color;
						white-space: nowrap;

						&:hover {
							color: $brand-primary;
							text-decoration: none;
						}
					}
				}
			}
		}

		.cta-nav {
			float: right;
			width: auto;
			text-transform: uppercase;
			text-align: right;
			margin-top: 0;
			background: $brand-secondary;

			.nav {
				width: 100%;
				margin-left: 0;
				margin-right: gutter(6);
				line-height: 80px;
				height: 80px;

				.sub-menu .menu-item {
					width: 100%;
					float: none;
					position: absolute;
					background: transparent;
				}

				.sub-menu .menu-spenden {
					left: 0;
				}

				.sub-menu .menu-mittun {
					right: 0;
					background: transparent;
				}

				> li {
					position: relative;
					font-size: ms(-1);
				}

				a {
					color: $white;
				}
			}
		}
	}

	@include breakpoint($medium) {
		.nav-primary {
			.nav {
				[data-dropdown="closed"] {
					& > .sub-menu {
						display: none;
					}

					& > a {
						text-decoration: none !important;
					}
				}

				[data-dropdown="opened"] {
					& > .sub-menu {
						display: block;
					}

					& > a {
						color: $white !important;
						text-decoration: none !important;
					}

					& > .sub-menu > li > a {
						color: $brand-primary !important;
					}
				}

				& > li {
					padding: 0 $padding-base-vertical / 2;
					font-size: ms(0);
				}

				& > .menu-item > .sub-menu {
					text-align: center;
					padding: $padding-base-vertical 0;

					& .menu-item {
						width: 100%;
						font-weight: 500;
						text-align: left;
						position: relative;

						&.menu-item-has-children:hover {
							> .sub-menu {
								display: block;
							}
						}

						&:hover {
							background-color: $off-white;
							font-weight: 700;
						}

						& .sub-menu {
							@include container();
							position: relative;
							opacity: 1;
							z-index: -1;
							visibility: visible;
							display: none;
							position: absolute;
							left: 100%;
							top: 0;
							font-size: ms(0);
						}

						& .sub-menu > .menu-item {
							width: 100%;
							padding: 0;
							font-weight: 300;
							text-align: center;
							background-color: transparent;

							&:hover {
								background-color: $off-white;

								a {
									color: $brand-primary !important;
								}
							}
						}
					}
				}
			}
		}

		.cta-nav {
			@include span(3 of 12);
			float: right;
			transition: opacity 0.3s;
			text-align: center;

			& .nav > .menu-item > a {
				opacity: 1;
				transition: opacity 0.3s;
				font-size: ms(-1);
			}

			&:hover {
				transition: opacity 0.3s;

				& .sub-menu {
					opacity: 1;
					transition: opacity 0.3s;
					visibility: visible;
				}

				& .nav > .menu-item > a {
					opacity: 0;
					transition: opacity 0.3s;
				}

				& .sub-menu > .menu-item {
					&:first-child {
						top: 0;
					}

					&:nth-child(2) {
						bottom: 10px;
					}
				}
			}

			.nav {
				text-align: center;

				& > li {
					font-size: ms(0);
					width: 100%;
				}
			}

			.menu-item {
				margin-bottom: 0;

				&:hover {
					background-color: inherit;
					color: $white;

					a {
						color: $white;
					}
				}
			}

			.cta-item {
				a {
					font-size: ms(-2);
				}
			}

			& .sub-menu {
				line-height: 1;
				position: static;
				top: 0;
				left: 0;
				width: 100%;
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.3s;

				.menu-item {
					margin-bottom: 0;
					line-height: 1;
					height: 40px;
					display: block;
					font-size: 16px;
					transition: all 0.3s;

					&:first-child {
						top: 20px;
					}

					&:nth-child(2) {
						bottom: 20px;
					}

					a {
						height: 40px;
						line-height: 40px;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	@include breakpoint($medium) {
		.cta-nav {
			width: span(2 of 12);
		}

		.nav-primary {
			@include span(10 of 12);

			.nav {
				& > li {
					padding: 0 $padding-base-vertical/2;
					font-size: ms(-1);
				}

				& > .menu-item > .sub-menu {
					text-align: center;
					padding: $padding-base-vertical 0;

					& .menu-item {
						width: 100%;
						font-weight: 500;
						text-align: left;
						padding-left: 0;
						padding-right: 0;
						position: relative;
						padding-left: $padding-base-vertical;
						padding-right: $padding-base-vertical;

						&.menu-item-has-children:hover {
							> .sub-menu {
								display: block;
							}
						}

						&:hover {
							background-color: $off-white;
						}

						& .sub-menu {
							@include container();
							padding: 0;
							position: absolute;
							opacity: 1;
							display: none;
							visibility: visible;
							left: 100%;
							top: 0;
						}

						& .sub-menu > .menu-item {
							width: 100%;
							padding: 0;
							font-weight: 500;
							text-align: center;
							background-color: transparent;
						}
					}
				}
			}
		}
	}

	@include breakpoint($large) {
		.nav-primary {
			.nav {
				& > li {
					padding: 0 $padding-base-vertical/3;
					font-size: ms(0);
				}
			}
		}
	}

	@media screen and (min-width: 1280px) {
		.nav-primary {
			@include span(10 of 12);

			.nav {
				& > li {
					padding: 0 $padding-base-vertical;
					font-size: ms(0);
				}
			}
		}
	}
}

// ===============
// SEARCHFORM STYLING
// ===============
.nav.search_form__container {
	@include container();
	width: 100%;
	line-height: 1;
	height: 80px;
	text-align: center;

	.search_form_trigger {
		&:hover {
			color: $brand-primary;
		}
	}

	@include breakpoint($medium) {
		float: right;
		width: auto;
		display: table;
		height: 80px;
		padding-right: 20px;
		padding-left: 20px;
	}
}

.search_form_trigger {
	display: none;

	@include breakpoint($medium) {
		margin-top: 20px;
		display: table-cell;
		vertical-align: middle;
		font-size: 12px;
	}
}

.search_form {
	height: 80px;
	width: 100%;
	z-index: 3;
	background-color: $white;
	margin-bottom: $padding-base-vertical;

	&.is-visible {
		transform: translateY(0);
		visibility: visible;
	}

	@include breakpoint($medium) {
		position: absolute;
		top: 0;
		height: 80px;
		left: 0;
		width: calc(83.3333333333% - 150px);
		visibility: hidden;
		transform: translateY(-100%);
		transition: transform 0.3s;
	}
}

.icon-search {
	display: block;
	font-size: 24px;
	margin-bottom: 5px;
}

.search-form {
	height: 80px;
	width: 100%;

	@include breakpoint($medium) {
		height: 80px;
	}
}

.search-field[type="search"] {
	@include span(10 of 12);
	box-sizing: border-box;
	border-radius: 0;
	border: none;
	color: $brand-primary;
	border-bottom: 1px solid $off-white;
	background: $white;
	height: 100%;
	padding-left: gutter(2);
	padding-right: gutter(2);
	font-size: ms(0);

	&:focus {
		outline: 0;
	}

	&::-webkit-input-placeholder {
		color: $brand-primary;
		text-overflow: ellipsis !important;
	}

	&:-moz-placeholder {
		/* Firefox 18- */
		color: $brand-primary;
		text-overflow: ellipsis !important;
	}

	&::-moz-placeholder {
		/* Firefox 19+ */
		color: $brand-primary;
		text-overflow: ellipsis !important;
	}

	&:-ms-input-placeholder {
		color: $brand-primary;
		text-overflow: ellipsis !important;
	}

	@include breakpoint($medium) {
		// box-shadow: inset 0 1px 0 #e2e3df, 0 3px 6px rgba(0, 0, 0, 0.05);
		-webkit-appearance: none;
		-moz-appearance: none;
		-ms-appearance: none;
		-o-appearance: none;
		appearance: none;
	}

	@include breakpoint($medium) {
		box-sizing: border-box;
		border-radius: 0;
		border: none;
		color: $brand-primary;
		background: $white;
		height: 100%;
		width: 100%;
		padding-left: gutter(6);
		padding-right: 50px;
		font-size: ms(3);
	}
}

.input-group-btn {
	@include span(2 of 12);
	position: relative;
	line-height: 80px;
	height: 80px;
	padding-top: 0;
	padding-bottom: 0;
	display: block;
	border-bottom: 1px solid $off-white;

	&:hover {
		background-color: $brand-secondary;
		border-color: $brand-secondary;
		color: $white;
	}

	.search-submit {
		height: 50px;
		width: 50px;
		display: inline;
		background: transparent;
		border: 0;
		line-height: 50px;
		top: 0;

		.icon--search {
		}
	}

	@include breakpoint($medium) {
		position: absolute;
		right: 0;
		top: 0;
		height: 80px;
		background-color: $brand-primary;
		display: none;

		&:hover {
			background-color: $brand-secondary;
			border-color: $brand-secondary;
			color: $white;
		}

		.search-submit {
			height: 80px;
			width: 80px;
			display: inline;
			background: transparent;
			border: 0;

			.icon-search:before {
				color: $white;
			}
		}
	}
}

#menu-hauptmenue {
	padding-top: $padding-base-vertical / 2;

	@include breakpoint($medium) {
		padding-top: 0;
	}
}

// =============
// OVERLAY STYLING
// =============
.overlay {
	position: fixed;
	z-index: 999;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	backface-visibility: hidden;
	overflow: hidden;

	&.is-visible {
		opacity: 1;
		visibility: visible;
	}
}

.csstransitions {
	.search_form {
		transition: transform 0.3s 0s, visibility 0s 0.3s;

		&.is-visible {
			transition: transform 0.3s 0s, visibility 0s 0s;
		}
	}

	.overlay {
		transition: transform 0.3s 0s, visibility 0s 0.3s, tranform 0.3s 0s;

		&.is-visible {
			transition: transform 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
		}

		&.is-visible.search-is-visible {
			transform: translateX(0);
		}
	}
}
