.quote {
	&__container {
		@include container();
		background-color: $off-white;
		padding: $padding-base-vertical;
	}

	&--no-image {
		.quote__author,
		.quote__bio {
			@include breakpoint($small) {
				@include span(12 of 12);
			}
		}
	}

	&__text {
		@include span(12 of 12);
		text-align: center;
		position: relative;

		&:before {
			content: "”";
			font-size: 100px;
			color: $brand-secondary;
			position: absolute;
			top: -70px;
			display: none;
			width: 100%;
			margin: 0 auto;

			@include breakpoint($small) {
				display: block;
			}
		}

		@include breakpoint($small) {
			@include span(7 of 12);
		}
	}

	&__author {
		@include span(12 of 12);

		@include breakpoint($small) {
			@include span(5 of 12);
		}
	}

	&__avatar {
		position: relative;
	}

	&__bio {
		@include container();
		position: relative;
		text-align: center;
		margin-top: $padding-base-vertical;
	}

	&__name {
		width: 50%;
		margin: 0 auto;
		font-weight: 500;
	}

	&__role {
		width: 75%;
		margin: 0 auto;
		text-transform: uppercase;
		font-size: ms(-1);
	}

	&__image_container {
		width: 50%;
		max-width: 90px;
		margin: 0 auto;

		@include breakpoint($small) {
			max-width: 128px;
		}
	}

	&__image {
		@include container();
		position: relative;
		height: 0;
		padding-bottom: 100%;
		overflow: hidden;
		border-radius: 50%;

		img {
			@include center();
			position: absolute;
		}
	}
}

.module.quote:last-child {
	margin-bottom: $padding-base-vertical;
}

.flexbox {
	.quote__container {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.quote__text {
		order: 1;

		@include breakpoint($small) {
			order: 2;
		}
	}

	.quote__author {
		order: 2;

		@include breakpoint($small) {
			order: 1;
		}
	}

	.quote--no-image {
		.quote__text {
			order: 1;

			@include breakpoint($small) {
				order: 1;
			}
		}

		.quote__author {
			order: 2;

			@include breakpoint($small) {
				order: 2;
			}
		}
	}
}

@include breakpoint($small) {
	.quote--full {
		.quote__text {
			max-width: 500px;
			margin: 0 auto;
		}
	}
}


@include breakpoint($medium) {
	.quote {
		&__container {
			padding: $padding-base-vertical * 4;
		}

		&--half {
			@include span(6 of 12);

			.quote__container {
				padding: $padding-base-vertical;
				min-height: 400px;
				border: $padding-base-vertical solid $white;
			}

			.quote__text {
				p {
					font-size: ms(0);
				}
			}
		}
	}
}
