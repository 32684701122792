.map {
	@include container();
	position: relative;
	background-color: $off-white;
	padding: $padding-base-vertical;
	// min-height: 700px;

	@include breakpoint($medium) {
		padding-top: $padding-base-vertical * 4;
		padding-bottom: $padding-base-vertical * 4;
	}

	&__wrapper {
		@include container();
		position: relative;
		max-width: 1000px;
	}

	&__headline,
	&__headline--team {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 20px auto;
			display: block;
		}
	}

	&__contacts_wrapper {
		@include container();
	}

	&__link {
		@include container();
		display: block;
		width: 100%;
		position: relative;
		text-align: center;
		margin-top: $padding-base-vertical;
		margin-bottom: $padding-base-vertical;
	}

	&__button {
		@extend %btn--secondary-outline-small;
		font-weight: 400;
		border-radius: 0;
	}

	&__svg {
		@include span(12 of 12);

		@include breakpoint($small) {
			@include span(7 of 12);
		}

		svg {
			width: 100%;
			fill: $white;
			stroke: $gray-light;
			min-height: 300px;

			 path:hover,
			 polygon:hover {
				fill: $brand-primary;
			}
		}
	}

	&__contacts {
		@include container();
		display: none;
		position: relative;
		top: 0;
		background-color: $white;
		border: 1px solid $off-white;
		padding: $padding-base-vertical;
		width: 100%;

		&.is-visible {
			display: block;
		}
	}

	&__contacts-container {
		@include span(12 of 12);
		position: relative;

		@include breakpoint($small) {
			@include span(5 of 12);
		}
	}

	&__intro {
		padding-left: $padding-base-vertical;
		padding-right: $padding-base-vertical;
	}

	&__contact {
		@include span(6 of 12);
		margin: $padding-base-vertical / 2 0;

		&:nth-child(2n +1) {
			clear: both;
		}
	}

	&__district {
		color: $brand-secondary;
		margin-top: 0;
		font-size: ms(0);
		margin-bottom: $padding-base-vertical / 2;
	}

	&__job {
		color: $brand-secondary;
		font-size: ms(-1);
		margin-top: 0;
	}

	&__name {
		font-size: ms(1);
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 0;
	}

	&__phone {
		margin-top: $padding-base-vertical / 2;
		margin-bottom: 0;
		font-size: ms(-1);
	}

	&__email {
		margin-top: 0;
		font-size: ms(-1);
	}
}

.district-name {
  pointer-events: none;
  position: absolute;
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 10px 15px;
  z-index: 5;
  line-height: 1;
  margin: 0 auto;
  color: $brand-primary;
  border-radius: 5px;
  box-shadow: 0 0 0 1px #eee;
  transform: translateX(-50%);
  display: none;

  &.active {
    display: block;
  }

  &::after {
	content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    margin-left: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}

.flexbox {
	.map__wrapper {
		@include breakpoint($small) {
			display: flex;
			align-items: flex-start;
		}
	}
}

.ui-tooltip {
	font-family: $font-family-base;
}
