.projects {
	padding: $padding-base-vertical;

	@include breakpoint($small) {
		padding: $padding-base-vertical * 2 0;
	}

	&__headline {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;
		margin-top: 0;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 0 auto;
			margin-top: 20px;
			display: block;
		}
	}


	&__list {
		@include container();
		list-style: none;
		padding: 0;
		max-width: 1000px;
	}

	&__listitem {
		@include span(12 of 12);
		margin-bottom: $padding-base-vertical * 2;

		@include breakpoint($small) {
			@include span(6 of 12);
			margin: $padding-base-vertical * 2 auto;

			&:nth-child(2n+1) {
				clear: both;
			}
		}
	}

	&__excerpt {
		p {
			margin: 0;
			font-size: ms(-1);

			@include breakpoint($small) {
				font-size: ms(0);
			}
		}
	}

	&__title {
		font-size: ms(1);
		font-weight: 500;
		margin: $padding-base-vertical / 2 0;

		@include breakpoint($small) {
			font-size: ms(2);
		}

		a {
			color: $text-color;
		}
	}

	&__meta {
		@include container();
		@include span(6 of 6);
		padding: 0;
	}

	&__categories {
		a {
			letter-spacing: 2px;
			text-transform: uppercase;
			color: $brand-secondary;
			font-weight: 500;
		}
	}

	&__date {
		@include span(4 of 6);
		color: $brand-secondary;
		padding-left: 0;
	}

	&__time {
		@include span(2 of 6);
		text-align: right;
		padding-right: 0;
		color: $gray-light;
	}

	&__location {
		@include span(6 of 6);
		font-size: ms(-1);
		padding: 0;

		a {
			color: $gray-light;
		}
	}

	&__detail {
		display: block;
		color: $gray-light;
		font-weight: 500;

		@include breakpoint($small) {
			margin-top: $padding-base-vertical;
		}
	}


	&__link-container {
		text-align: center;
	}

	&__link-button {
		@extend %btn--secondary;
		text-transform: uppercase;
		font-weight: 400;
	}
}

.flexbox {

}
