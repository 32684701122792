// ----------
// Stage
// ----------
.stage {
  @include container();
  padding: $padding-base-vertical;

  @include breakpoint($small) {
    padding: $padding-base-vertical * 2;
  }

  &__container {
    margin-bottom: 1em;

    @include breakpoint($medium) {
      @include span(4 of 12);
    }
  }

  &__content {
    background-color: $off-white;
    height: 100%;
  }

  &__headline {
    background: rgba($brand-secondary, 1);
    padding: $padding-base-vertical;
    text-align: center;
    color: $white;
    margin: 0;

    @include breakpoint($small) {
      padding: $padding-base-vertical * 2;
    }
  }

  &__text {
    padding: $padding-base-vertical;
    flex-shrink: 0;

    @include breakpoint($small) {
      padding: $padding-base-vertical * 2;
    }

    p {
      margin-top: 0;
    }
  }

  &__link-container {
    padding-bottom: $padding-base-vertical * 2;
    text-align: center;
  }

  &__link {
    @extend %btn--primary;
  }
}

.flexbox {
  .stage {
    display: flex;
    flex-wrap: wrap;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
