// --------------
// Gallery Styles
// --------------

.gallery {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;

	&--gray {
		background-color: $off-white;
	}

	&--white {
		background-color: $white;
	}

	&__list {
		@include container();
		margin-left: auto;
		margin-right: auto;
		list-style: none;
		max-width: 800px;
		padding: 0;

		&.has-5-columns {
			max-width: 1140px;

			.gallery__item {
				@include breakpoint($small) {
					@include span(4 of 12);

					&--regular + &--full {
						@include span(4 of 12);
					}
				}

				@include breakpoint($medium) {
					@include span(2.4 of 12);

					&--regular + &--full {
						@include span(2.4 of 12);
					}
				}
			}
		}
	}

	&__caption {
		font-size: ms(-1);
		margin-top: $padding-base-vertical / 2;
	}

	&__item_container {
		position: relative;
		height: 0;
		padding-bottom: 100%;
		overflow: hidden;
	}

	&__image {
		position: absolute;
		@include cover();

		img {
			min-height: 100%;
			min-width: 100%;
			display: block;
		}

		&.landscape img {
			max-width: none;
			width: auto;
			height: 100%;
		}

		&.portrait img {
			max-width: 100%;
			max-height: none;
		}
	}

	&__item {
		@include span(12 of 12);
		position: relative;
		margin-bottom: $padding-base-vertical / 2;

		@include breakpoint($small) {
			@include span(6 of 12);

			&--full {
				@include span(12 of 12);
			}

			&--regular + &--full {
				@include span(6 of 12);
			}
		}

		@include breakpoint($medium) {
			@include span(4 of 12);

			&--full {
				@include span(12 of 12);
			}

			&--regular + &--full {
				@include span(4 of 12);
			}
		}
	}

	&__title {
		display: block;
		color: $text-color;
		text-align: center;
		font-weight: 400;
	}
}
