// ---------------
// Postlist Styles
// ---------------

.postlist {
	@include container();
	position: relative;
	padding: $padding-base-vertical;
	max-width: 1100px;

	@include breakpoint($small) {
		padding: $padding-base-vertical * 2 0;
	}

	&__headline {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;
		margin-top: 0;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 0 auto;
			margin-top: 20px;
			display: block;
		}
	}

	&__list {
		@include container();
		margin-left: auto;
		margin-right: auto;
		list-style: none;
		max-width: 1100px;

		@include breakpoint($small) {
			padding: $padding-base-vertical * 2;
			border-top: 1px solid $off-white;
		}
	}

	&__list--left,
	&__list--right {
		@include container();
		@include span(12 of 12);
		color: $text-color;

		@include breakpoint($small) {
			@include span(6 of 12);
		}
	}

	&__list--left {
		@include breakpoint($small) {
			border-right: 1px solid $off-white;
		}
	}

	&__list--right {
		border-top: 1px solid $off-white;

		@include breakpoint($small) {
			border-top: 0;
		}

		.postlist__listitem {
			border-bottom: 1px solid $off-white;
		}

		.postlist__title {
			font-size: ms(0);

			@include breakpoint($small) {
				font-size: ms(2);
			}

			a {
				color: $text-color;
			}
		}
	}

	&__category {
		display: block;

		.post-categories {
			list-style: none;
			display: inline-block;
			padding: 0;
			margin: 0;
			text-transform: none;
			font-size: ms(0);

			li {
				display: inline;

				a {
					color: $brand-secondary;
					margin-right: .5em;
					font-weight: 400;
				}
			}
		}
	}

	&__listitem--latest {
		.post-categories {
			list-style: none;
			padding: 0;
			text-transform: none;
			letter-spacing: 0;

			& li a {
				color: $brand-secondary;
				font-weight: 400;
				font-size: ms(0);
			}
		}
	}

	&__link-container {
		text-align: center;
		margin-top: $padding-base-vertical * 2;
	}

	&__link-button {
		@extend %btn--secondary;
		text-transform: uppercase;
		font-weight: 400;
	}

	&__meta {
		color: $gray-light;
		margin-top: $padding-base-vertical;
		font-size: ms(-1);

		@include breakpoint($small) {
			font-size: ms(0);
		}
	}

	&__excerpt {
		p {
			margin: 0;
			font-size: ms(-1);

			@include breakpoint($small) {
				font-size: ms(0);
			}
		}
	}

	&__listitem {
		@include span(12 of 12);
		position: relative;
		margin-bottom: $padding-base-vertical / 2;
	}

	&__title {
		display: block;
		color: $text-color;
		text-align: left;
		font-weight: 500;
		font-size: ms(1);
		margin-bottom: $padding-base-vertical / 2;

		@include breakpoint($small) {
			font-size: ms(3);
		}

		a {
			color: $text-color;
		}
	}
}
