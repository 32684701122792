.restricted__content {
  padding-top: $padding-base-vertical * 2;
  padding-bottom: $padding-base-vertical * 2;
}

.latest-posts {
  @include container();

  &__wrapper {
    @include span(12 of 12);
    padding-left: 0;
    padding-right: 0;

    @include breakpoint($small) {
      @include span(5 of 12);
      padding-right: 0;
      padding-left: 0;
    }

    @include breakpoint($medium) {
      @include span(3 of 12);
      padding-right: 0;
      padding-left: 0;
    }
  }

  &__list {
    height: 100%;
  }

  .slider__container {
    @include container();
    @include span(12 of 12);
    padding-right: 0;
    padding-left: 0;
    position: relative;

    @include breakpoint($small) {
      @include span(7 of 12);
      padding-right: 0;
      padding-left: 0;
    }

    @include breakpoint($medium) {
      @include span(9 of 12);
      padding-right: 0;
      padding-left: 0;
    }
  }

  .slider {
    @include container();
    position: relative;
    height: 400px;

    @include breakpoint($small) {
      height: 100%;
    }

    .slide {
      @include container();
      position: relative;
    }
  }

  .slideshow__content {
    position: absolute;
    width: auto;
    top: 20px;
    right: 0;
    text-align: right;
    z-index: 11;
    background-color: rgba($brand-secondary, 0.6);
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: $brand-secondary;
    }

    @include breakpoint($small) {
      top: 80px;
    }
  }

  .slideshow__headline,
  .slideshow__subtitle {
    color: $white;
    font-weight: 500;
    width: auto;

    a {
      color: $white;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .slideshow__headline {
    margin-top: 0;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: ms(2);

    @include breakpoint($small) {
      font-size: ms(7);
    }
  }

  .slideshow__subtitle {
    margin-top: 0;
    font-weight: 300;
    font-size: ms(0);
    margin-bottom: 0;

    @include breakpoint($small) {
      font-size: ms(3);
    }
  }

  .slideshow__link-container {
    width: span(11 of 12);
    margin: 0 auto;
    margin-top: $padding-base-vertical * 2;
  }

  .slideshow__link {
    @extend %btn--white-small;

    &:hover {
      text-decoration: none;
      background-color: $white;
    }

    &:focus {
      outline: 0;
      text-decoration: none;
      color: $white;
    }

    @include breakpoint($medium) {
      font-size: ms(-2);
    }
  }

  .latest-posts__listitem {
    border-top: 1px solid $off-white;
    border-bottom: 1px solid $off-white;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;

    &:hover {
      background-color: $off-white;
    }
  }

  .latest-posts__title {
    font-size: ms(0);
    margin-top: 0;
    margin-bottom: 0;

    @include breakpoint($small) {
      margin-bottom: 1em;
    }
  }

  .latest-posts__link {
    font-size: ms(-1);
  }

  .lSSlideOuter,
  .lSSlideWrapper {
    height: 100%;
    min-height: 200px;

    @include breakpoint($small) {
      min-height: 500px;
    }
  }

  .lSSlideOuter {

    @include breakpoint($small) {
      .slide {
        display: block;
        height: 100%;
        float: left;
        position: relative;

        img {
          min-height: 100%;
          min-width: 100%;
          width: auto;
          height: auto;
          max-width: none;
          display: block;
        }
      }
    }
  }
}

.cta_button {
  position: absolute;
  z-index: 11;
  right: $padding-base-vertical;
  bottom: $padding-base-vertical;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background-color: $white;
  text-align: center;
  display: table;
  overflow: hidden;

  &__link {
    display: table-cell;
    vertical-align: middle;
    text-transform: uppercase;

    span {
      font-size: 8px;
      width: 100%;
      display: block;
    }

    .icon-phone {
      font-size: 24px;
      margin-bottom: 5px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  @include breakpoint($small) {
    padding: $padding-base-vertical;
    right: $padding-base-vertical * 2;
    bottom: $padding-base-vertical * 2;
    width: 200px;
    height: 200px;

    .icon-phone {
      font-size: 36px;
      margin-bottom: 10px;
    }

    span {
      font-size: 10px;
      width: 100%;
      display: block;
    }
  }
}

.flexbox {
  .latest-posts__list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .latest-posts__listitem {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
  }

  .latest-posts {
    display: flex;
    flex-direction: column;
  }

  .latest-posts__wrapper {
    order: 2;
  }

  .slider__container {
    order: 1;
  }

  @include breakpoint($small) {

    .latest-posts {
      flex-direction: row;
    }

    .slider__container {
      order: 2;
    }

    .latest-posts__wrapper {
      order: 1;
    }
  }
}



.termine {
  @include container();
  margin-top: 40px;

  .content__container,
  .entry-content {
    padding: $padding-base-vertical;

    p {
      font-size: ms(0);
    }
  }

  .entry-content {
    max-width: 800px;
    margin: 0 auto;
  }

  .content__container {
    max-width: 400px;
    margin: 0 auto;
  }

	.download {
		clear: both;
	}

  .entry-title {
    font-size: ms(2);
    color: $brand-secondary;
    font-weight: 500;
    margin-top: $padding-base-vertical;
  }

  strong {
    font-weight: 500;
  }

  .content__left {
    @include span(12 of 12);

    @include breakpoint($medium) {
      @include span(4 of 12);
    }
  }

  .content__right {
    @include span(12 of 12);

    @include breakpoint($medium) {
      @include span(8 of 12);
    }
  }
}

.related_posts,
.related_events,
.related_projects {
  position: relative;
  padding: $padding-base-vertical * 2 0;

  &__container {
    @include container();
    max-width: 1000px;
    margin: 0 auto;
  }

  &__headline {
    font-size: ms(3);
    width: span(11 of 12);
    margin-right: auto;
    margin-left: auto;
    max-width: 800px;
    text-align: center;
    color: $gray-light;
    font-weight: 500;
    position: relative;
    margin-bottom: $padding-base-vertical * 2;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:after {
      content: "";
      height: 2px;
      width: 50px;
      background-color: $gray-light;
      margin: 20px auto;
      display: block;
    }
  }
}

.related_posts {
  .related_post {
    @include breakpoint($medium) {
      @include span(6 of 12);
    }

    .post__title {
      font-weight: 500;
      font-size: ms(2);
      margin: $padding-base-vertical / 2 0;

      a {
        color: $brand-secondary;
      }
    }

    .post__content {
      p {
        font-size: ms(0);
      }
    }

    .post__detail {
      color: $gray-light;
      font-weight: 500;
    }
  }
}

.related_projects {
  .related_project {
    @include breakpoint($medium) {
      @include span(6 of 12);
    }

    .project__title {
      font-weight: 500;
      font-size: ms(2);
      margin: $padding-base-vertical / 2 0;

      a {
        color: $brand-secondary;
      }
    }

    .project__content {
      p {
        font-size: ms(0);
      }
    }

    .project__detail {
      color: $gray-light;
      font-weight: 500;
    }
  }
}

.related {
  &__link-container {
    text-align: center;
    margin-top: $padding-base-vertical * 2;
  }

  &__link-button {
    @extend %btn--secondary;
    text-transform: uppercase;
    font-weight: 400;
  }
}

.related_events {
  .related_event {
    @include breakpoint($medium) {
      @include span(6 of 12);
    }
  }
}

.flexbox {
  .related_event,
  .termine .related_post .post__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .events__meta {
    order: 1;
  }

  .events__title,
  .termine .post__title {
    order: 2;
  }

  .events__detail {
    order: 3;
  }
}

.search-results {
  .main {
    max-width: 800px;
    margin: 2em auto;
  }

  .search-title {
    color: $gray-light;
    font-weight: 500;
    margin-top: 0;
  }

  article {
    margin-top: $padding-base-vertical * 2;
  }

  header {
    .entry-title {
      margin-bottom: 0;
    }

    .entry-title a {
      color: $brand-secondary;
      font-weight: 400;
      font-size: ms(1);
      margin-bottom: 0;
    }
  }

  .entry-summary {
    p {
      font-size: ms(0);
    }
  }
}

.recentposts {
  margin-top: $padding-base-vertical * 2;
  margin-bottom: $padding-base-vertical * 2;

  @include breakpoint($small) {
    margin-top: $padding-base-vertical * 4;
    margin-bottom: $padding-base-vertical * 4;
  }
}

.recent_post {
  margin-bottom: $padding-base-vertical * 2;

  @include breakpoint($small) {
    margin-bottom: $padding-base-vertical * 4;
  }
}

.related_posts .related_post .recent_posts__title.post__title {
  margin-bottom: 0;
}

.post__content p {
  margin-top: $padding-base-vertical / 2;
}

.recent_posts__permalink {
  font-weight: 500;
  color: $gray-light;
}

.related_posts .termine .recent_posts__title a {
  color: $text-color;
}

.error404 {
  .alert {
    top: 80px;
  }
}

.error-content {
  margin-top: $padding-base-vertical * 4;
  margin-bottom: $padding-base-vertical * 4;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  h1,
  h2 {
    font-weight: 500;
    color: $gray-light;
    margin: 0;
  }

  a {
    margin-top: $padding-base-vertical;
    display: block;
  }
}
