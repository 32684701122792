// --------------
// Download Styles
// --------------
.download {
  @include container();
  position: relative;
  padding: $padding-base-vertical 0;

  @include breakpoint($small) {
    padding: $padding-base-vertical * 2 0;
  }

  &__list {
    @include container();
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    max-width: 1000px;
    padding: $padding-base-vertical;

    @include breakpoint($small) {
      padding: $padding-base-vertical * 2;
    }
  }

  &__item {
    @include container();
    position: relative;
    margin-bottom: $padding-base-vertical / 2;
    border-top: 1px solid $gray-light;
    padding-top: $padding-base-vertical;
    padding-bottom: $padding-base-vertical;

    @include breakpoint($small) {
      padding-top: $padding-base-vertical * 2;
      padding-left: $padding-base-vertical * 2;
      padding-right: $padding-base-vertical * 2;
      padding-bottom: $padding-base-vertical * 2;
    }

    &:last-child {
      border-bottom: 1px solid $gray-light;
    }
  }

  &__image,
  &__link {
    @include span(6 of 12);

    @include breakpoint($small) {
      @include span(3 of 12);
    }
  }

  &__image {
    position: relative;
    display: none;

    @include breakpoint($small) {
      display: block;
    }
  }

  &__image-container {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;

    img {
      @include center();
      position: absolute;
      min-height: 100%;
      width: auto;
      height: auto;
      max-width: none;
    }
  }

  &__image-container--portrait {
    img {
      height: 100%;
    }
  }

  &__image-container--landscape {
    img {
      width: 100%;
      min-height: auto;
    }
  }


  &__gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($brand-secondary, 1);
    color: $white;
    text-transform: uppercase;
    font-weight: 300;
    display: table;

    span {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      text-align: center;
      font-size: ms(6);
    }
  }

  &__link {

    a {
      display: block;
      color: $gray-light;
      text-align: center;
      text-transform: uppercase;
      font-size: ms(-1);
      transition: color .3s;

      &:hover {
        text-decoration: none;
        color: $brand-primary;
      }
    }

    .icon {
      display: block;
      font-size: ms(5);
      text-align: center;
      margin-bottom: 10px;
    }

    @include breakpoint($small) {
      a {
        font-size: ms(0);
      }

      .icon {
        font-size: ms(10);
      }
    }
  }

  &__info {
    @include span(6 of 12);
  }

  &__meta {
    color: $gray-light;
    text-transform: uppercase;
    font-size: ms(-1);
  }

  &__title {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-size: ms(1);
    color: $text-color;
    font-weight: 500;
  }

  p,
  ul {
    font-size: ms(-1);
  }
}

.flexbox {
  .download__item {
    @include breakpoint($small) {
      display: flex;
      align-items: center;
    }
  }
}
