.people {
	@include container();
	position: relative;
	padding: $padding-base-vertical;

	&__headline {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;
		margin-top: 0;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 0 auto;
			margin-top: 10px;
			display: block;
		}
	}

	&__container {
		@include container();
		position: relative;
		max-width: 800px;
		margin: 0 auto;
	}

	&__inner_container {
		@include container();
	}

	&__avatar {
		@include span(4 of 12);
		max-width: 128px;
		padding-right: gutter(2);
		padding-left: 0;
	}

	&__avatar_wrapper {
		border-radius: 50%;
		box-shadow: inset 0 0 10px $black;
		width: 100%;
		height: auto;
		padding-top: 100%;
		position: relative;
		overflow: hidden;
	}

	&__avatar_wrapper img {
		@include center();
		max-width: 100%;
		max-height: 100%;
		position: absolute;
	}

	&__detail {
		@include span(12 of 12);
	}

	&__avatar + &__detail {
		@include span(8 of 12);
	}

	&__contact {
		@include span(12 of 12);
		margin: $padding-base-vertical / 2 0;

		@include breakpoint($small) {
			@include span(6 of 12);
			margin: $padding-base-vertical * 2 0;

			&:nth-child(2n +1) {
				clear: both;
			}
		}
	}

	&__district {
		color: $brand-secondary;
		margin-top: 0;
		font-size: ms(-2);
		margin-bottom: $padding-base-vertical / 2;

		a {
			color: $brand-secondary;
		}

		@include breakpoint($small) {
			font-size: ms(0);
		}
	}

	&__job {
		color: $brand-secondary;
		font-size: ms(-2);
		margin-top: 0;

		a {
			color: $brand-secondary;
		}

		@include breakpoint($small) {
			font-size: ms(0);
		}
	}

	&__name {
		font-size: ms(0);
		font-weight: 500;
		margin-top: 0;
		margin-bottom: 0;

		@include breakpoint($small) {
			font-size: ms(2);
		}
	}

	&__phone {
		margin-top: $padding-base-vertical / 2;
		margin-bottom: 0;
		font-size: ms(-2);

		@include breakpoint($small) {
			font-size: ms(0);
		}
	}

	&__email {
		margin-top: 0;
		font-size: ms(-2);

		@include breakpoint($small) {
			font-size: ms(0);
		}
	}
}

.flexbox {
	.people__inner_container {
		display: flex;
		flex-wrap: wrap;
		flex: 0 auto;
		align-items: flex-start;
	}
}
