//----------
// Variables
//----------

// Colors
$brand-primary:						#282a6d;
$brand-secondary:					#8a2e23;

$gray-base:								#000 !default;
$gray-darker:							lighten($gray-base, 13.5%) !default;	// #222
$gray-dark:								lighten($gray-base, 20%) !default;		// #333
$gray:										lighten($gray-base, 33.5%) !default;	// #555
$gray-light:							lighten($gray-base, 46.7%) !default;	// #777
$gray-lighter:						lighten($gray-base, 93.5%) !default;	// #eee
$off-white:								#f2f2f2;
$black:										$gray-base;
$white:										#fff;
$footer-bg:								#f2f2f2;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:									$white !default;
//** Global text color on `<body>`.
$text-color:							$gray-dark !default;

//** Global textual link color.
$link-color:							$brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:				darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration:		underline !default;


//== Typography
//
//## Font, line-height for body text, headings, and more.

$font-family-sans-serif:	'Work Sans', sans-serif !default;
$font-family-base:				$font-family-sans-serif !default;

$font-size-base:					16px !default;

$ms-base: 1em, 1.125em;
$ms-ratio: 1.333;

$typi: (
	null: ms(0),
	xs: ms(0),
	small: ms(0),
	medium: ms(1),
	large: ms(1)
);

$h1-map: (
	null: (ms(3) 1.2),
	large: (ms(4), 1.3)
);

$h1-feature-map: (
	null: (ms(5) 1),
	small: (ms(9), 1),
	large: (ms(10), 1)
);

$h2-feature-map: (
	null: (ms(3) 1.1),
	small: (ms(6) 1.1),
	large: (ms(7), 1)
);

$h2-map: (
	null: ms(2),
	large: ms(3)
);

$h2-cta-map: (
	null: ms(3),
	small: ms(4),
	large: ms(5)
);

$h3-map: (
	null: ms(1),
	large: ms(2)
);

$h3-footer-map: (
	null: ms(1),
	large: ms(1)
);

$h4-map: (
	null: ms(0),
	large: ms(1)
);

$breakpoints: (
	xs: 480px,
	small: 768px,
	medium: 992px,
	large: 1200px,
);

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:				1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:		floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:		inherit !default;
$headings-font-weight:		500 !default;
$headings-line-height:		1.1 !default;
$headings-color:					inherit !default;


//== Components
//
//## Define common padding and border radius sizes and more.

$padding-base-vertical:		1em !default;
$padding-base-horizontal:	1em !default;

$border-radius-base:			3px !default;

$navbar-height:						50px !default;
$navbar-margin-bottom:		$line-height-computed !default;

$navbar-default-color:		$text-color !default;
$navbar-default-bg:				$white !default;

$input-height-base:				40px;
$input-border-focus:			$brand-secondary;
$input-color-placeholder:	lighten($brand-secondary, 20%);


//== Breakpoints
//
//

@include breakpoint-set('to ems', true);

$xs:											480px;
$small:										768px;
$medium:									992px;
$large:										1200px;


//== Grid system
//
//## Define your custom responsive grid.

$susy: (
	columns: 12,
	gutters: .25,
	math: fluid,
	output: float,
	gutter-position: inside,
);
