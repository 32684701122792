h1 {
	@include typi($h1-map);
}

h2 {
	@include typi($h2-map);
}

h3 {
	@include typi($h3-map);
}

h4 {
	@include typi($h4-map);
}

@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.eot?rty1pw");
	src: url("../fonts/icomoon.eot?rty1pw#iefix") format("embedded-opentype"),
		url("../fonts/icomoon.ttf?rty1pw") format("truetype"),
		url("../fonts/icomoon.woff?rty1pw") format("woff"),
		url("../fonts/icomoon.svg?rty1pw#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../fonts/work-sans-v17-latin-300.woff2") format("woff2"),
		url("../fonts/work-sans-v17-latin-300.woff") format("woff");
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../fonts/work-sans-v17-latin-500.woff2") format("woff2"),
		url("../fonts/work-sans-v17-latin-500.woff") format("woff");
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../fonts/work-sans-v17-latin-700.woff2") format("woff2"),
		url("../fonts/work-sans-v17-latin-700.woff") format("woff");
}

@font-face {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/work-sans-v17-latin-regular.woff2") format("woff2"),
		url("../fonts/work-sans-v17-latin-regular.woff") format("woff");
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon", "sans-serif" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-phone-hang-up:before {
	content: "\e943";
}

.icon-bubbles2:before {
	content: "\e96d";
}

.icon-users:before {
	content: "\e972";
}

.icon-search:before {
	content: "\e986";
}

.icon-download2:before {
	content: "\e9c5";
}

.icon-arrow-down:before {
	content: "\ea36";
}

.icon-arrow-down2:before {
	content: "\ea3e";
}

.icon-mail_outline:before {
	content: "\e0e1";
}

.icon-send:before {
	content: "\e163";
}

.icon-envelop:before {
	content: "\e945";
}
