.form_wrapper.gform_wrapper {
	max-width: 1000px;
	margin: 0 auto;
	padding: $padding-base-vertical;

	.field_sublabel_above .ginput_complex.ginput_container label {
		margin-top: $padding-base-vertical / 2;
		margin-bottom: $padding-base-vertical;
		text-transform: uppercase;
	}

	.top_label .gfield_label {
		font-weight: 400;
		text-transform: uppercase;
		font-size: ms(-1);
	}

	ul li.gfield {
		margin-top: $padding-base-vertical * 2;

		&:first-child {
			margin-top: 0;
		}
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
	.ginput_complex select {
		display: block;
		width: 100%;
		padding: $padding-base-vertical / 2 $padding-base-vertical / 2;
		font-size: 1rem;
		line-height: 1.25;
		color: $text-color;
		background-color: $white;
		background-clip: padding-box;
		border: 2px solid $off-white;
		-webkit-appearance: none;
		border-radius: 0;

		&:focus {
			outline: 0;
			border-color: $brand-primary;
			color: $brand-primary;
		}
	}

	.ginput_complex select {
		height: 40px;
		background-image: url("../images/dropdown_arrows.png");
		background-repeat: no-repeat;
		background-position: 97% center;
		cursor: pointer;
	}

	.gform_footer input[type=submit] {
		@extend %btn--primary;
	}

	.gfield_checkbox li input[type="checkbox"]:checked+label {
		font-weight: 400;
	}

	.gfield_checkbox,
	.gfield_radio {
		background-color: $white;
		width: 100%;
		padding: $padding-base-vertical / 4;
		font-size: ms(1);

		li {
			margin-bottom: 0;
			cursor: pointer;

			label {
				margin-left: 0;
			}
		}

		li:first-child {
			margin-bottom: gutter(3);
		}

		input[type="radio"],
		input[type="checkbox"] {
			position: absolute;
			overflow: hidden;
			clip: rect(0 0 0 0);
			height: 1px;
			width: 1px;
			margin: -1px;
			padding: 0;
		}

		input[type="radio"] + label {
			position: relative;

			&::before {
				content: "";
				position: relative;
				display: inline-block;
				background: $white;
				border: 2px solid $brand-primary;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				cursor: pointer;
				margin-right: $padding-base-vertical / 4;
				vertical-align: middle;
			}

			&::after {
				content: "";
				position: absolute;
				cursor: pointer;
				left: 5px;
				display: block;
				height: 10px;
				width: 10px;
				top: 10px;
				background: $brand-primary;
				border-radius: 100%;
				transition: opacity 0.3s;
				opacity: 0;
			}
		}

		/* Base for label styling */
		[type="checkbox"]:not(:checked),
		[type="checkbox"]:checked {
			position: absolute;
			left: -9999px;
		}

		[type="checkbox"]:not(:checked) + label,
		[type="checkbox"]:checked + label {
			position: relative;
			padding-left: 25px;
			cursor: pointer;
		}

		/* checkbox aspect */
		[type="checkbox"]:not(:checked) + label:before,
		[type="checkbox"]:checked + label:before {
			content: '';
			position: absolute;
			left:0;
			top: 2px;
			width: 17px;
			height: 17px;
			border: 2px solid $gray-light;
			background: $white;
			border-radius: 0;
		}

		/* checked mark aspect */
		[type="checkbox"]:not(:checked) + label:after,
		[type="checkbox"]:checked + label:after {
			content: '✔';
			position: absolute;
			top: 3px;
			left: 4px;
			font-size: 18px;
			line-height: 0.8;
			color: $brand-primary;
			transition: all .2s;
		}

		/* checked mark aspect changes */
		[type="checkbox"]:not(:checked) + label:after {
			opacity: 0;
		}

		[type="checkbox"]:checked + label:after {
			opacity: 1;
		}

		/* disabled checkbox */
		[type="checkbox"]:disabled:not(:checked) + label:before,
		[type="checkbox"]:disabled:checked + label:before {
			box-shadow: none;
			border-color: #bbb;
			background-color: #ddd;
		}

		[type="checkbox"]:disabled:checked + label:after {
			color: #999;
		}

		[type="checkbox"]:disabled + label {
			color: #aaa;
		}

		/* accessibility */
		[type="checkbox"]:checked:focus + label:before,
		[type="checkbox"]:not(:checked):focus + label:before {
			border: 2px solid $brand-primary;
		}

		/* hover style just for information */
		label:hover:before {
			border: 2px solid $brand-primary !important;
		}

		input[type="radio"]:checked + label::after {
			opacity: 1;
			transition: opacity .3s;
		}
	}
}
