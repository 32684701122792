
// _ty-breakpoint
// ==============
// Allows use of breakpoint-sass and mappy-bp.
// Falls back to default method otherwise.
@mixin _ty-breakpoint(
  $typi-map
) {
  $breakpoint: map-get($typi-map, breakpoint);
  $breakpoints: map-get($typi-map, breakpoints);
  $bp-val: map-get($breakpoints, $breakpoint);

  @if $typi-breakpoint == breakpoint {
    @if mixin-exists(breakpoint) {
      @include breakpoint-set('to ems', true);
      @include breakpoint(map-get($breakpoints, $breakpoint)) {
        @include _ty-write-props($typi-map);
      }
    } @else {
      @error 'Breakpoint-sass Library not found'
    }
  }

  @else if $typi-breakpoint == mappy-breakpoint or $typi-breakpoint == mappy-breakpoints {
    @if mixin-exists(mappy-bp) {
      @include mappy-bp(map-get($breakpoints, $breakpoint)) {
        @include _ty-write-props($typi-map);
      }
    } @else {
      @error 'Mappy Breakpoints Library not found'
    }
  }

  @else {
    @media all and (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @include _ty-write-props($typi-map);
    }
  }
}

// _ty-write-props
// ===============
// Writes font-size (and line height) properties
@mixin _ty-write-props (
  $typi-map
) {
  $target: map-get($typi-map, target);
  $breakpoint: map-get($typi-map, breakpoint);
  $typi: map-get($typi-map, typi);
  $base: map-get($typi-map, base);
  $rem: map-get($typi-map, rem);

  $bp-val: map-get($target, $breakpoint);
  $font-size: null;
  $line-height: null;

  @if type-of($bp-val) == 'null' {
    $last-valid-breakpoint: map-get($typi-map, last-valid-breakpoint);
    $bp-val: map-get($target, $last-valid-breakpoint);
  }

  @if type-of($bp-val) == "list" and length($bp-val) > 1 {
    $font-size: nth($bp-val, 1);
    $line-height: nth($bp-val, 2);
    font-size: _ty-font-size($font-size, $breakpoint, $typi, $rem, $base, $typi-map);
    line-height: $line-height;
  }
  @else  {
    $font-size: $bp-val;
    font-size: _ty-font-size($font-size, $breakpoint, $typi, $rem, $base, $typi-map);
  }
}