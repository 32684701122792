// --------------
// Feature Module
// --------------
.feature {
  @include container();
  padding: $padding-base-vertical * 2 0;
  text-align: left;
  position: relative;
  height: 300px;
  background-size: cover;
  background-position: center center;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba($brand-secondary, .6);
    top: 0;
    left: 0;
    z-index: 1;
  }

  &--photo {
    background-size: cover;
    background-position: center center;
    color: $white;

    &.feature--no-bg {
      background-color: $brand-secondary;

      &:before {
        display: none;
      }
    }
  }

  &__background--video {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  &__video {
    display: none;

    @include breakpoint($medium) {
      display: block;
    }
  }

  &__video--fullscreen {
    bottom: 0;
    height: 100%;
    position: absolute;
    width: auto;

    @include breakpoint($large) {
      height: auto;
      width: 100%;
    }
  }

  &__link {
    @extend %btn--white-small;

    &:hover {
      text-decoration: none;
      background-color: $brand-primary;
      border-color: $brand-primary;
    }

    &:focus {
      outline: 0;
      text-decoration: none;
      color: $white;
    }

    @include breakpoint($medium) {
      font-size: ms(-2);
    }
  }

  &__container {
    position: relative;
    z-index: 3;
    margin-right: auto;
    margin-left: auto;
  }

  &__content {
    display: block;
    width: span(10 of 12);
    margin-left: span(1 of 12);

    @include breakpoint($medium) {
      width: span(10 of 12);
      margin-left: span(1 of 12);
      margin-top: $padding-base-vertical * 2;
    }
  }

  &__headline {
    @include typi($h1-feature-map);
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__subheadline {
    @include typi($h2-feature-map);
    margin-top: $padding-base-vertical / 2;
    margin-bottom: $padding-base-vertical;
    font-weight: 300;
    color: $white;
  }

  @include breakpoint($small) {
    height: 400px;
  }

  @include breakpoint($medium) {
    height: 550px;
  }
}
