// --------------
// Call to Action
// --------------
.cta {
	@include container();
	padding: $padding-base-vertical * 2 0;
	text-align: center;
	position: relative;

	&--photo {
		background-size: cover;
		background-position: center center;
		color: $white;

		.cta__link {
			@extend %btn--white-small;
			color: $white;
		}

		&:after {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			background: rgba($brand-secondary, 0.6);
			top: 0;
			left: 0;
		}

		&:nth-child(even):after {
			background: rgba($brand-primary, 0.6);
		}
	}

	&--gradient {
		color: $white;
		background: $brand-secondary;

		&:not(.cta--half):nth-child(even) {
			background: $brand-primary;
		}

		.cta__link {
			@extend %btn--white;
		}
	}

	&__link-container {
		margin-top: $padding-base-vertical * 1.5;
	}

	&--boxed {
		background-color: $white;
		border: 2px solid $brand-primary;
		color: $brand-primary;
		margin: $padding-base-vertical 0;

		.cta__text {
			img {
				max-width: 128px;

				&.large-image {
					max-width: 250px;
				}
			}

			a {
				color: $brand-primary;
			}
		}

		.cta__link {
			text-decoration: underline;

			&:hover {
				color: $brand-secondary;
			}
		}
	}

	&__container {
		position: relative;
		z-index: 1;
		margin-right: auto;
		margin-left: auto;
	}

	&__content {
		display: inline-block;
		width: 95%;
	}

	&__headline {
		@include typi($h2-cta-map);
		margin-top: 0;
		margin-bottom: 0;
		font-weight: 400;
	}

	&__text {
		margin-bottom: 0;
		margin-top: $padding-base-vertical * 1.5;

		p {
			margin: 0;
			font-weight: 300;
		}

		a {
			color: $white;
		}
	}

	&--half {
		margin-top: $padding-base-vertical / 2;
		margin-bottom: $padding-base-vertical / 2;
	}

	@include breakpoint($small) {
		position: relative;
		display: block;

		&__wrapper {
			display: table;
			width: 100%;
			height: 300px;
		}

		&__container {
			display: table-cell;
			text-align: center;
			vertical-align: middle;
		}
	}

	@include breakpoint($medium) {
		&--half.cta--gradient + .cta--boxed.cta--full {
			clear: both;
		}

		&--half {
			@include container();
			@include span(6 of 12);
			border: $padding-base-vertical solid $white;
			position: relative;
			min-height: 400px;
		}

		&--half.cta--gradient + .quote--half {
			margin-top: $padding-base-vertical / 2;
		}

		&--half.cta--boxed {
			margin-top: $padding-base-vertical / 2;
			margin-bottom: $padding-base-vertical / 2;
		}

		&--half.cta--boxed:before {
			content: " ";
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 2px solid $brand-primary;
		}

		&--gradient {
			.cta__text {
				p {
					font-size: 16px;
					max-width: 800px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}
}

:nth-child(4n - 1 of .cta--gradient.cta--half),
:nth-child(4n - 2 of .cta--gradient.cta--half) {
	background: $brand-primary;
}
