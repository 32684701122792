// --------------
// Logo Styles
// --------------

.logo {
	@include container();
	position: relative;
	padding: $padding-base-vertical * 2 0;

	@include breakpoint($small) {
		padding: $padding-base-vertical * 2 0;
	}

	@include breakpoint($medium) {
		padding: $padding-base-vertical * 4 0;
	}

	&__wrapper {
		max-width: 1170px;
		margin: 0 auto;
	}

	&__headline {
		width: span(11 of 12);
		margin-right: auto;
		margin-left: auto;
		max-width: 800px;
		text-align: center;
		color: $gray-light;
		font-weight: 500;
		margin-top: 0;
		text-transform: uppercase;
		letter-spacing: 1px;

		&:after {
			content: "";
			height: 2px;
			width: 50px;
			background-color: $gray-light;
			margin: 0 auto;
			margin-top: 20px;
			display: block;
		}
	}

	&__list {
		@include container();
		margin-left: auto;
		margin-right: auto;
		list-style: none;
		padding: 0;

		@include breakpoint($small) {
			padding: $padding-base-vertical * 2;
		}
	}

	&__item {
		@include span(12 of 12);
		position: relative;
		margin-bottom: $padding-base-vertical * 2;
		text-align: center;
		display: block;

		&:nth-child(2n+1) {
			clear: both;
		}

		img {
			max-width: 128px;
		}

		&--larger {
			@include span(12 of 12);

			img {
				max-width: 200px;
			}
		}

		@include breakpoint($small) {
			@include span(2 of 10);
			margin-bottom: $padding-base-vertical * 2;

			img {
				max-width: 128px;
			}

			&--larger {
				@include span(2 of 12);
				width: 21.66667%;
				img {
					max-width: 100%;
				}
			}
		}
	}

	&__image {
		display: block;
	}

	&__title {
		display: block;
		color: $text-color;
		text-align: center;
		font-weight: 400;
	}
}

.flexbox {
	.logo {
		&__list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-around;
		}

		&__item--regular {
			flex-grow: 1;
		}
	}
}
