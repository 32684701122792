#mc_embed_signup {
	font-family: "Work Sans", sans-serif;
	display: block;
	margin: 15px auto;
	max-width: 500px;
	font-size: 16px;
	width: 95%;

	@media screen and (min-width: 1024px) {
		width: 100%;
	}

	select,
	input {
		width: 100%;
		height: 50px;
		padding: 10px;
		display: block;
		border: 1px;
		border-color: #ddd;
		background-color: #f4f4f4;
		background-image: none;
		text-shadow: none;
		color: #444;
		font-size: 14px;
		line-height: 20px;
		margin: 0;
		line-height: normal;
		box-sizing: border-box;

		&:focus-visible {
			outline: none;
		}
	}

	input[type="submit"] {
		background-color: #444;
		color: #fff;
		width: auto;
		height: auto;
		margin: 0;
	}

	input[type="checkbox"] {
		max-width: 20px;
		display: inline-block;
		width: auto;
		height: auto;
	}

	label {
		font-family: "Work Sans", sans-serif;
		display: block;
		color: inherit;
		font-size: 16px;
		font-weight: 700;
		line-height: normal;
		padding: 5px;
		margin: 0;

		+ br {
			display: none;
		}
	}

	ul {
		padding-left: 0;
		text-align: left;

		li {
			display: flex;
		}

		br {
			display: none;
		}
	}

	.brandingLogo {
		display: none;
	}
}
